import { buildSearchParamsFromFilters } from 'lib/search/searchUtils'
import { take } from 'lib/array/arrayUtils'
import React, { useContext, useMemo } from 'react'
import styled from 'styled-components'
import TrackingContext, { TrackingProps } from 'contexts/trackingContext'
import { createYouMayAlsoLikeTileViewForOffers, createYouMayAlsoLikeTileViewForToursV2, createYouMayAlsoLikeTileViewForBedbank } from 'components/OfferList/OfferListTiles/YouMayAlsoLikeTile/Common/createYouMayAlsoLikeTileView'
import { OFFER_TYPE_ALWAYS_ON, OFFER_TYPE_BED_BANK, OFFER_TYPE_HOTEL, OFFER_TYPE_LAST_MINUTE, OFFER_TYPE_TOUR, OFFER_TYPE_VILLA } from 'constants/offer'
import YouMayAlsoLikeTile from 'components/OfferList/OfferListTiles/YouMayAlsoLikeTile/XSmall/YouMayAlsoLikeTile'
import { connect } from 'react-redux'
import { DistanceUnit } from 'lib/geo/distanceUnits'
import TrackedCardCarousel from 'components/Common/TrackedCardCarousel'
import { getDefaultDepositAmountPercentage } from 'checkout/selectors/featureConfig/deposit'
import CurrencyContext from 'contexts/currencyContext'
import { useOffersAvailableRates } from 'hooks/useOfferAvailableRates'
import { rem } from 'polished'
import useOffersBestPrices from 'hooks/useOfferBestPrices'
import { isTourV2Offer } from 'lib/offer/offerTypes'

interface Props {
  offers: Array<App.Offer | App.Tours.TourV2Offer | App.BedbankOffer | App.OfferSummary>;
  offerDistances?: Record<string, number | null>;
  offerDistanceFrom?: string | null;
  offerDistanceUnit?: DistanceUnit;
  filters?: App.OfferListFilters;
  tracking?: TrackingProps;
  displayLimit?: number;
  pageSize?: number;
  defaultDepositAmountPercentage: number;
  onTileClick?: () => void;
  arrowsOnlyOnHover?: boolean;
}

const Root = styled.div`
  width: 100%;
`

interface Params {
  currency: string;
  linkQuery?: URLSearchParams;
  testId: string;
  position: number;
  tracking?: TrackingProps;
  distance?: number;
  distanceUnit?: DistanceUnit;
  defaultDepositAmountPercentage: number;
  currentRegionCode?: string;
}

function isSupportedBestPriceOffer(
  offer: App.Offer | App.Tours.TourV2Offer | App.BedbankOffer | App.OfferSummary,
): offer is (App.Offer | App.BedbankOffer | App.OfferSummary) {
  return !isTourV2Offer(offer)
}

export function getYMALViewForOffer(
  offer: App.Offer | App.Tours.TourV2Offer | App.BedbankOffer | App.OfferSummary,
  params: Params,
) {
  switch (offer.type) {
    case 'direct_tour':
    case 'partner_tour':
    case 'connection_tour':
      return {
        ...createYouMayAlsoLikeTileViewForToursV2({ ...params, offer }),
        distanceUnit: params.distanceUnit,
      }
    case OFFER_TYPE_BED_BANK:
      return createYouMayAlsoLikeTileViewForBedbank({ ...params, offer })
    case OFFER_TYPE_TOUR:
    case OFFER_TYPE_ALWAYS_ON:
    case OFFER_TYPE_LAST_MINUTE:
    case OFFER_TYPE_VILLA:
    case OFFER_TYPE_HOTEL:
      return createYouMayAlsoLikeTileViewForOffers({ ...params, offer })
    default:
      return undefined
  }
}

function YouMayAlsoLikeOffersCarousel({
  filters,
  offerDistances,
  offerDistanceFrom,
  offerDistanceUnit,
  offers,
  tracking,
  displayLimit = 3,
  pageSize,
  defaultDepositAmountPercentage,
  arrowsOnlyOnHover = false,
}: Props) {
  const linkQuery = useMemo(() => buildSearchParamsFromFilters(filters), [filters])
  const offersToDisplay = useMemo(() => Number.isFinite(displayLimit) ? take(offers, displayLimit) : offers, [offers, displayLimit])

  const currency = useContext(CurrencyContext)
  const offerAvailableRates = useOffersAvailableRates(offersToDisplay.map(p => p.id), filters)

  // only fetch best prices if we are using exact dates (no monthIndex)
  const selectedDatesAndRoomsFilter = useMemo(() => ({
    checkIn: filters?.checkIn,
    checkOut: filters?.checkOut,
    rooms: filters?.rooms,
  }), [filters])
  // we do not support the tours for the exact day checking
  const supportedBestPriceOffers = useMemo(() => {
    return offers.filter(isSupportedBestPriceOffer)
  }, [offers])
  const bestPrices = useOffersBestPrices(supportedBestPriceOffers, selectedDatesAndRoomsFilter)

  return (
    <TrackingContext.Provider value={tracking}>
      <Root>
        <TrackedCardCarousel pageSize={pageSize} width="95%" tabletWidth={rem(320)} arrowsOnlyOnHover={arrowsOnlyOnHover}>
          {offersToDisplay.map((offer, idx) => {
            const params = {
              linkQuery,
              testId: 'ymal-tile-condensed',
              position: idx + 1,
              currency,
              offerAvailableRates: offerAvailableRates[offer.id],
              distance: offerDistances?.[offer.id] ?? undefined,
              distanceFrom: offerDistanceFrom,
              distanceUnit: offerDistanceUnit,
              defaultDepositAmountPercentage,
            }
            const props = getYMALViewForOffer(offer, params)
            if (props) {
              return (
                <YouMayAlsoLikeTile
                  offer={offer}
                  key={offer.id}
                  alternativePrice={bestPrices[offer.id]}
                  {...props}
                />
              )
            } else {
              return null
            }
          })}
        </TrackedCardCarousel>
      </Root>
    </TrackingContext.Provider>
  )
}

function mapStateToProps(state: App.State) {
  return {
    defaultDepositAmountPercentage: getDefaultDepositAmountPercentage(state),
  }
}

export default connect(mapStateToProps)(YouMayAlsoLikeOffersCarousel)
