import { getUnit } from 'lib/geo/distanceUnits'
import { take, without } from 'lib/array/arrayUtils'
import { hasOfferExpired } from 'lib/offer/offerUtils'
import { lereDlpMainListKey, lereDlpYmalListKey } from 'lib/customer/recommendationUtils'
import { scheduleIsCurrent } from 'lib/offer/scheduleStatusUtils'
import { createSelector } from 'reselect'

export const getDistanceUnit = createSelector(
  (state: App.State) => state.geo.currentRegionCode,
  (regionCode) => {
    return getUnit(regionCode)
  },
)
export const getOfferExpired = createSelector(
  (state: App.State, offerId: string) => state.offer.offers[offerId],
  hasOfferExpired,
)

export const getExpiredOfferRecommendationsState = createSelector(
  (state: App.State, offerId: string) => state.offer.offers[offerId] ?? state.offer.offerSummaries[offerId],
  (state: App.State, offerId: string) => state.recommendations.recommendations[offerId],
  (offer, recommendationsState) => {
    const offerExpired = hasOfferExpired(offer)
    const offerIds = recommendationsState?.offerIds || []

    const loadingState: App.ApiResponseState = (() => {
      if (!recommendationsState) {
        return 'loading'
      }

      return recommendationsState.state
    })()

    return {
      offerExpired,
      offerIds,
      loadingState,
      displayMinLimit: offerIds.length > 1,
    }
  },
)

export const getOfferBasedLereState = createSelector(
  (state: App.State, seedOffer: App.Offer | App.OfferSummary | App.BedbankOffer | App.BedbankOfferSummary) => seedOffer,
  (state: App.State, offer: App.AnyOffer) => state.recommendations.recommendations[offer?.id],
  (seedOffer, recommendationsForOfferId) => {
    const recommendationOfferIds = without(recommendationsForOfferId?.offerIds ?? [], (seedOffer as App.AnyOffer)?.id)
    const recommendationLoadingError = recommendationsForOfferId?.error
    const recommendationLoadingState = recommendationsForOfferId?.state

    return {
      offerIds: recommendationOfferIds,
      hasEnoughRecommendationsToDisplay: recommendationOfferIds.length > 1,
      loadingState: recommendationLoadingState,
      loadingError: recommendationLoadingError,
    }
  },
)

const MAX_MAIN_LIST_OFFERS_COUNT = 10

export function isOfferAvailable(offer: App.Offer | App.OfferSummary) {
  // may not work for other types than hotel
  // so if a property is undefined we consider it as positive
  const isSoldOut = !!offer?.isSoldOut

  const isOnlinePurchaseActive = (offer && offer.onlinePurchaseSchedule === undefined) || scheduleIsCurrent(offer?.onlinePurchaseSchedule)

  return !isSoldOut && isOnlinePurchaseActive
}

export const getLereLocationSeededYmalOffers = createSelector(
  (state: App.State, destinationId: string) => state.recommendations.locationSeededRecommendations[lereDlpMainListKey(destinationId)],
  (state: App.State, destinationId: string, offerTypes?: Array<string>) => state.recommendations.locationSeededRecommendations[lereDlpYmalListKey(destinationId, offerTypes)],
  (mainRecommendationOffers, ymalRecommendationOffers): { offerIds: Array<string>; loadingState: App.ApiResponseState } => {
    try {
      if (!mainRecommendationOffers || mainRecommendationOffers.state === 'loading') {
        return { offerIds: [], loadingState: 'loading' }
      }
      if (!ymalRecommendationOffers || ymalRecommendationOffers.state === 'loading') {
        return { offerIds: [], loadingState: 'loading' }
      }

      const mainListDisplayableOfferIds = take(mainRecommendationOffers.offerIds ?? [], MAX_MAIN_LIST_OFFERS_COUNT)

      const ymalOfferIds = (ymalRecommendationOffers.offerIds || [])

      // Remove offers that already appear in the main list
      const ymalOfferIdsNotInMainList = without(ymalOfferIds, ...mainListDisplayableOfferIds)

      return { offerIds: ymalOfferIdsNotInMainList, loadingState: ymalRecommendationOffers.state }
    } catch {
      return { offerIds: [], loadingState: 'loading' }
    }
  },
)
